import { SideMenuState } from '../context/SideMenuContext'
import { useState, useCallback } from 'react'

export const useSideMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const setSideMenu = useCallback(
    (state: SideMenuState) => {
      setIsOpen(state === 'open')
    },
    [isOpen]
  )

  return {
    isOpen,
    setSideMenu
  }
}
