import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import { SessionProvider } from 'next-auth/react'
import { config } from '@fortawesome/fontawesome-svg-core'
import { SideMenuContext } from '../context/SideMenuContext'
import { Nunito } from '@next/font/google'
import { useSideMenu } from '../hooks/useSideMenu'
import useNavProgress from '../hooks/useNavProgress'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

export const nunito = Nunito({ subsets: ['latin'] })

config.autoAddCss = false

const App = ({ Component, pageProps }: AppProps) => {
  const { isOpen, setSideMenu } = useSideMenu()
  useNavProgress()

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="shortcut icon" href="/unifiber_favicon.png" type="image/x-icon" />
        <title>Unifiber - B2B Portal</title>
      </Head>
      <SessionProvider session={pageProps.session} refetchWhenOffline={false}>
        <SideMenuContext.Provider value={{ isOpen, setSideMenu }}>
          <main className={`${nunito.className} d-flex flex-column vh-100`}>
            <Component {...pageProps} />
            <ToastContainer
              toastStyle={{
                fontFamily: nunito.style.fontFamily,
                fontStyle: nunito.style.fontStyle
              }}
            />
          </main>
        </SideMenuContext.Provider>
      </SessionProvider>
    </>
  )
}

export default appWithTranslation(App)
