import { createContext } from 'react'

export type SideMenuState = 'open' | 'close'

export interface SideMenuContextInterface {
  isOpen: boolean
  setSideMenu: (state: SideMenuState) => void
}

export const SideMenuContext = createContext<SideMenuContextInterface>({
  isOpen: false,
  setSideMenu: () => {}
})
